.main-body.hidden-sidebar{
    .main-sidebar{
        display: none!important;
    }
    .main-content{
        max-width: 100%!important;
    }
}

.label-support-close{
    cursor: pointer;
    text-transform: none;
    .ls-info-circle{
        // font-size: var(--font-size-base);
    }
    &.no-point{
        cursor: default;
    }
}

.list-group{
    &-support-hours{
        .list-group-item-heading{

        }
    }
}

.panel-sidebar{
    &.panel-support-hours{
        .panel-body{
            .list-group-item {
                .operating-hour-title,
                .operating-hour-zone{
                    display: block;
                    font-size: var(--font-size-sm);
                    line-height: var(--line-height-sm);
                    color: var(--gray-base);
                }
                .operating-hour-val {
                    font-size: var(--font-size-xlg);
                    font-weight: var(--font-weight-base);
                    line-height: var(--line-height-lg);
                    color: var(--gray-darker);
                }
            }
            > .list-group-item{
                padding: var(--spacing-3x) var(--spacing-3x);
            }
        }
        .panel-footer{
            padding-top: 0!important;
        }
        .support-status{
            display: flex;
            flex-direction: row;
            padding-right: 0px;
            font-size: var(--font-size-sm);
            &-text{
                padding-right: var(--spacing-2x);
                margin: auto 0;
            }
            .label{
                position: static;
                height: 100%;
                margin-left: auto;
            }
        }
    }
}

.support-time{
    padding: 0 var(--panel-body-padding) var(--panel-body-padding);
    margin-top: calc(-1 * var(--spacing-1x));
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    & + .support-time{
        padding-top: 0;
        margin-top: calc(-1 * var(--spacing-1x));
    }
    span{
        font-size: var(--font-size-base);
        color: var(--gray-darker);
        margin: auto 0 auto var(--spacing-1x);
        
    }
    .operating-desc{
        display: none;
    }
}

.list-group-support-hours{
    width: 100%;
    .list-group-item-heading{
        display: flex;
        justify-content: space-between;
    }
    .support-time-box{
        justify-content: flex-end;
    }
    .support-time{
        padding: 0 0 0 var(--spacing-2x);
        margin-top: 0;
        & + .support-time{
            margin-top: var(--spacing-1x);
        }
        span{
            font-size: var(--font-size-base);
            line-height: var(--line-height-base);
            color: var(--gray-darker);
            margin-left: var(--spacing-1x);
            br{
                display: none;
            }
        }
        .operating-desc{
            display: flex;
            margin-left: 0;
        }
        label{
            order: 2;
            margin-left: var(--spacing-1x);
        }
        .operating-desc{
            color: var(--text-body-color);
            line-height: var(--line-height-base);
        }
    }
}


@media (max-width: 767px){
    .list-group{
        &-support-hours{
            .list-group-item-heading{
                flex-direction: column;
                .support-time-box{
                    justify-content: flex-start;
                    .operating-desc{
                        margin-left: 0;
                    }
                }
            }
            .support-time{
                padding: 0;
            }
        }
    }
}